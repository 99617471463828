import Routerx from '@/routerx'
import { useStore } from '@/store'
import { BarChartOutlined, BookOutlined, DashboardOutlined, DotChartOutlined, EditOutlined, LogoutOutlined } from '@ant-design/icons'
import type { MenuProps } from 'antd'
import { Avatar, Dropdown, Flex, Layout, Menu, Spin } from 'antd'
import React, { Suspense } from 'react'

import { Link, Outlet, useLocation } from 'react-router-dom'
import './index.css'

const { Sider } = Layout

const sideItems: MenuProps['items'] = [
  {
    key: '/',
    label: <Link to="/">Dashboard</Link>,
    icon: <DashboardOutlined />,
  },
  // {
  //   key: 'market',
  //   label: '市场',
  //   icon: <SlidersOutlined />,
  //   children: [
  //     { key: '/page1/sub1', label: <Link to="/market">市场概览</Link> },
  //     { key: '/page1/sub2', label: <Link to="/market">实时行情</Link> },
  //     { key: '/page1/sub3', label: <Link to="/market">历史行情</Link> },
  //   ],
  // },
  {
    key: '/batch',
    label: <Link to="/batch">股池</Link>,
    icon: <DotChartOutlined />,
  },
  { key: '/position', icon: <BarChartOutlined />, label: <Link to="/position">持仓</Link> },
  { key: '/note', icon: <BookOutlined />, label: <Link to="/note">日志</Link> },
]

const MainLayout: React.FC = () => {
  const { pathname } = useLocation()
  const { loginStore } = useStore()

  const signout = () => {
    loginStore.loginOut()
    Routerx.navigate('/login')
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      onClick: () => signout(),
      label: (
        <a rel="singout">
          <EditOutlined /> 修改密码
        </a>
      ),
    },
    {
      key: '2',
      onClick: () => signout(),
      label: (
        <a rel="singout">
          <LogoutOutlined /> 退出
        </a>
      ),
    },
  ]

  return (
    <Layout style={{ height: '100vh' }}>
      <Flex
        className="header"
        justify="space-between"
        align="center"
      >
        <div className="logo" />
        <Dropdown menu={{ items }}>
          <a onClick={(e) => e.preventDefault()}>
            <div className="user-info">
              <Avatar
                className="user-name"
                style={{ backgroundColor: '#1677ff', verticalAlign: 'middle' }}
                gap={2}
              >
                admin
              </Avatar>
            </div>
          </a>
        </Dropdown>
      </Flex>
      <Layout>
        <Sider
          width={208}
          style={{ background: 'transparent' }}
        >
          <Menu
            mode="inline"
            style={{ height: '100%', backgroundColor: '#f5f5f5' }}
            defaultSelectedKeys={[pathname]}
            defaultOpenKeys={[pathname]}
            items={sideItems}
          />
        </Sider>
        <Layout className="layout-content">
          <Suspense
            fallback={
              <div
                style={{
                  textAlign: 'center',
                  marginTop: 200,
                }}
              >
                <Spin />
              </div>
            }
          >
            <Outlet />
          </Suspense>
        </Layout>
      </Layout>
    </Layout>
  )
}

export default MainLayout
